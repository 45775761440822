import React, { useState } from "react";
import Image from "next/image";
import logo from "../../public/images/footerLogo.webp";
import Link from "next/link";
import { storefront } from "@/helpers/store";

const QuickLinks = [
  {
    name: "Home",

    link: "/",
  },
  {
    name: "Products",

    link: "/products",
  },
  {
    name: "Wholesale",

    link: "/wholesale",
  },
  {
    name: "Blog",

    link: "/blog",
  },
  {
    name: "Terms & Returns",

    link: "/returns",
  },
  {
    name: "Contact us",

    link: "/contact-us",
  },
];

function Footer() {
  const [newsletterMail, setNewsletterMail] = useState("");

  async function signUpForMarketing() {
    try {
      const query = `
        mutation($email: String!) {
          customerSubscribe(email: $email) {
            customer {
              id
              email
            }
          }
        }
      `;

      const variables = {
        email: "developerTesting@gmail.com",
      };

      const response = await storefront(query, variables);

      if (response.errors) {
        throw new Error("Failed to sign up for marketing");
      }

      return response.data.customerSubscribe.customer;
    } catch (error) {
      console.error("Failed to sign up for marketing:", error);
    }
  }

  return (
    <>
      <div className="text-white flex lg:flex-row flex-col lg:space-y-0 space-y-14 items-start justify-evenly bg-[#2c436e] h-auto py-8 w-screen">
        {/* 1st */}
        <div className="space-y-7 px-8 lg:px-0 lg:w-[20%]">
          <div>
            <Link href="/">
              <Image src={logo} alt="footer logo" />
            </Link>
          </div>
          <h2 className="text-4xl">
            The Ultimate <br /> Surprise Gift!
          </h2>
          <p className="lg:w-full w-[75%]">
            A fun alternative to a glitter bomb, send a friend for life instead.
          </p>
          <div className="lg:hidden flex items-center space-x-6">
            <svg
              width={10}
              height={23}
              viewBox="0 0 15 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="cursor-pointer hover:fill-[#1BA2DD] fill-white"
            >
              <path d="M10.7276 0C11.4548 0 12.1867 0.0396253 12.9124 0.0722579C13.3856 0.091682 13.8587 0.159278 14.3032 0.20745L14.2698 4.58643H13.9217C13.4407 4.58643 12.9606 4.58022 12.4796 4.58022C12.1199 4.58022 11.7586 4.5841 11.3981 4.59653C10.2381 4.6346 9.3842 5.06971 9.32515 6.5203C9.28786 7.56377 9.26144 9.86903 9.25988 9.99023C9.31971 9.99101 10.4673 9.9949 11.6211 9.9949C12.1984 9.9949 12.7624 9.99334 13.1556 9.99023H14.0934C13.858 11.6724 13.6428 13.2869 13.4081 14.9108H9.26066L9.16743 27.1977H4.07286L4.16609 14.8913H0L0.0372944 10.0003H4.22281L4.22592 9.63283C4.23369 8.64686 4.22126 7.66089 4.25778 6.67492C4.27254 6.05568 4.30673 5.41779 4.41705 4.79932C4.69987 3.30055 5.39525 2.0442 6.64928 1.14525C7.74791 0.362066 8.99727 0.0334096 10.322 0.0046618C10.4572 0.00155393 10.5924 0 10.7276 0Z" />
            </svg>

            <svg
              width={22}
              height={17}
              viewBox="0 0 27 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="cursor-pointer hover:fill-[#1BA2DD] fill-white"
            >
              <path d="M26.1061 3.16355C25.9204 3.44404 25.7347 3.7051 25.5202 3.95606C24.9733 4.6328 24.3408 5.23261 23.6307 5.74464L23.5429 5.90857C23.5662 6.67233 23.5312 7.43609 23.4286 8.19985C23.2228 9.80506 22.7566 11.3419 22.069 12.8018C21.3425 14.3099 20.4133 15.6727 19.2439 16.8716C17.2564 18.9111 14.8781 20.2257 12.109 20.8349C11.1596 21.0377 10.2024 21.1441 9.22571 21.1736C9.06954 21.1775 8.91415 21.1798 8.75953 21.1798C5.96013 21.1798 3.36583 20.434 0.976662 18.95L0.851562 18.8723C1.26103 18.9165 1.66583 18.9383 2.06597 18.9383C3.43187 18.9383 4.7465 18.6827 6.02927 18.1668C6.9119 17.7993 7.72694 17.3354 8.49536 16.7263C5.81871 16.6393 4.15134 14.7062 3.71857 13.1398C4.03946 13.2027 4.35957 13.233 4.67813 13.233C5.09691 13.233 5.51492 13.181 5.93292 13.0823L6.01062 13.0435C4.55303 12.7047 3.43731 11.9316 2.67355 10.6745C2.15842 9.84313 1.92378 8.92476 1.93077 7.92946C2.66267 8.31639 3.41556 8.52851 4.22748 8.54793C3.14051 7.76475 2.41405 6.73061 2.12424 5.41598C1.83443 4.10135 2.03722 2.85432 2.71318 1.67489C5.48228 4.97155 9.00661 6.77878 13.325 7.07869C13.2978 6.8658 13.2706 6.69176 13.2426 6.50839C13.1074 5.29011 13.3669 4.15885 14.032 3.1348C14.8634 1.83883 16.0397 1.037 17.5494 0.79536C17.8702 0.741749 18.1841 0.715332 18.4895 0.715332C19.8414 0.715332 21.0372 1.23435 22.0628 2.23508C22.1272 2.29335 22.1809 2.32365 22.2523 2.32365C22.2764 2.32365 22.3028 2.32054 22.3324 2.31277C23.3976 2.09056 24.4061 1.70363 25.3478 1.16209L25.4161 1.12402C25.0393 2.32288 24.288 3.25057 23.2197 3.93663C24.2258 3.83019 25.1753 3.55981 26.1061 3.16355Z" />
            </svg>
            <svg
              width={18}
              height={18}
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="cursor-pointer hover:fill-[#1BA2DD] fill-white"
            >
              <path d="M18.505 4.18652H18.5221C19.3146 4.18652 19.9571 4.83451 19.9517 5.63634C19.9455 6.42962 19.2936 7.07684 18.4902 7.07684C17.6876 7.06751 17.0552 6.41952 17.0606 5.62702C17.0668 4.83063 17.7101 4.18652 18.505 4.18652Z" />
              <path d="M23.9321 6.04247C24.1108 7.93749 24.2266 16.8889 23.6687 18.9867C22.9228 21.7318 21.1319 23.3564 18.3543 23.8008C17.3163 23.9679 14.513 24.0689 11.7128 24.0689C8.84732 24.0689 5.98497 23.9632 5.02387 23.7138C2.29361 23.0083 0.66353 21.2298 0.229982 18.4646C-0.0924665 16.4344 -0.126646 6.99969 0.459185 4.95005C1.22373 2.28195 3.00453 0.715586 5.70528 0.271161C6.92899 0.070704 9.32205 0 11.7376 0C13.9846 0 16.2518 0.0613804 17.6162 0.135969C19.2688 0.222989 20.7823 0.754434 22.0107 1.93387C23.1816 3.05503 23.7798 4.45746 23.9321 6.04247ZM21.6168 18.0684C21.9617 15.9511 21.9625 8.2273 21.7652 6.24526C21.5492 4.1288 20.2547 2.73647 18.1593 2.40782C17.1228 2.25009 14.6248 2.18017 12.1401 2.18017C9.74317 2.18017 7.35787 2.24543 6.3082 2.35965C4.20806 2.60128 2.79709 3.89649 2.46143 5.98419C2.11646 8.19855 2.12114 16.4732 2.37909 18.1359C2.64635 19.8663 3.62302 21.0558 5.34941 21.5391C6.28411 21.8001 9.08119 21.8941 11.8239 21.8941C14.3498 21.8941 16.8283 21.8149 17.7607 21.7131C19.8795 21.4808 21.2819 20.1755 21.6168 18.0684Z" />
              <path d="M12.0907 5.86868C15.493 5.86868 18.2373 8.6339 18.2108 12.0463C18.1852 15.4487 15.399 18.2139 11.9866 18.2139C8.57413 18.2139 5.83997 15.4487 5.8664 12.0362C5.89204 8.6238 8.67823 5.85935 12.0907 5.86868ZM12.0005 16.0291C14.1885 16.0291 15.9903 14.2669 16.026 12.0751C16.0625 9.86151 14.3066 8.06361 12.1031 8.04418C12.0907 8.0434 12.079 8.0434 12.0674 8.0434C9.87943 8.0434 8.07764 9.80556 8.04191 11.9974C8.00538 14.2016 9.76055 16.0096 11.9648 16.0291H12.0005Z" />
            </svg>
            <svg
              width={17}
              height={17}
              viewBox="0 0 23 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="cursor-pointer hover:fill-[#1BA2DD] fill-white"
            >
              <path d="M3.16899 0.420898C4.63821 0.420898 5.8091 1.60966 5.79744 3.06958C5.78656 4.5295 4.59781 5.71826 3.12858 5.71826C1.66865 5.71826 0.48921 4.5295 0.500074 3.06958C0.510969 1.60966 1.70904 0.420898 3.16899 0.420898Z" />
              <path d="M5.40504 7.71973L5.29315 22.423H0.720703L0.832581 7.71973H5.40504Z" />
              <path d="M17.0374 7.36182C21.6681 7.36182 22.4955 10.3974 22.4653 14.3607L22.4039 22.4233H17.8408L17.8951 15.2791C17.9084 13.5682 17.8866 11.3733 15.5471 11.3733C13.1689 11.3733 12.7975 13.2295 12.7827 15.1532L12.7268 22.4233H8.1543L8.26697 7.71922H12.646L12.6304 9.73001H12.698C13.3157 8.5801 14.8137 7.36182 17.0374 7.36182Z" />
            </svg>
            <svg
              width={21}
              height={14}
              viewBox="0 0 25 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="cursor-pointer hover:fill-[#1BA2DD] fill-white"
            >
              <path d="M24.3698 2.4394C25.2602 4.78894 25.2244 13.3441 24.153 15.6059C23.8585 16.2243 23.3916 16.6501 22.7987 16.9694C21.9604 17.4022 17.0935 17.6066 12.3253 17.6066C7.93615 17.6066 3.63101 17.4333 2.62325 17.1046C1.6878 16.7954 1.05459 16.1762 0.722802 15.2485C-0.0813668 12.9766 -0.260047 3.46421 1.34902 1.58862C1.89523 0.950727 2.60384 0.5739 3.43597 0.486879C5.34963 0.280205 9.03945 0.179199 12.6787 0.179199C17.21 0.179199 21.6628 0.336147 22.5081 0.641495C23.4047 0.960827 24.038 1.54977 24.3698 2.4394ZM9.47219 12.648C12.0541 11.3038 14.6258 9.97985 17.2271 8.6357C14.646 7.28222 12.0945 5.94895 9.53359 4.6048C9.51339 7.29233 9.49239 9.95111 9.47219 12.648Z" />
            </svg>
          </div>
        </div>
        {/* 2nd */}
        <div className="space-y-4 px-8 lg:px-0 lg:w-[10%]">
          <h2 className="text-semibold text-3xl lg:text-2xl">Quick Links</h2>
          <div className="space-y-8 lg:space-y-6">
            {QuickLinks.map((link, key) => (
              <Link key={key} href={link.link}>
                <p className="py-1 cursor-pointer">{link.name}</p>
              </Link>
            ))}
          </div>
        </div>
        {/* 3rd */}
        <div className="space-y-4 px-8 lg:px-0 lg:w-[10%]">
          <h2 className="text-semibold text-3xl lg:text-2xl">Contact Us</h2>
          <div className="space-y-4 lg:space-y-2">
            <p className="cursor-pointer">info@sloths.co.uk</p>
            <p className="">Send a Sloth</p>
            <p className="">London, UK</p>
          </div>
        </div>
        {/* 4th */}
        <div className="space-y-4 px-8 lg:px-0 lg:w-[20%]">
          <h2 className="text-semibold text-2xl">Get in Touch</h2>

          <div className="hidden lg:flex items-center space-x-6">
            <a href="https://www.facebook.com/sendasloth">
              <svg
                width={10}
                height={23}
                viewBox="0 0 15 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="cursor-pointer hover:fill-[#1BA2DD] fill-white"
              >
                <path d="M10.7276 0C11.4548 0 12.1867 0.0396253 12.9124 0.0722579C13.3856 0.091682 13.8587 0.159278 14.3032 0.20745L14.2698 4.58643H13.9217C13.4407 4.58643 12.9606 4.58022 12.4796 4.58022C12.1199 4.58022 11.7586 4.5841 11.3981 4.59653C10.2381 4.6346 9.3842 5.06971 9.32515 6.5203C9.28786 7.56377 9.26144 9.86903 9.25988 9.99023C9.31971 9.99101 10.4673 9.9949 11.6211 9.9949C12.1984 9.9949 12.7624 9.99334 13.1556 9.99023H14.0934C13.858 11.6724 13.6428 13.2869 13.4081 14.9108H9.26066L9.16743 27.1977H4.07286L4.16609 14.8913H0L0.0372944 10.0003H4.22281L4.22592 9.63283C4.23369 8.64686 4.22126 7.66089 4.25778 6.67492C4.27254 6.05568 4.30673 5.41779 4.41705 4.79932C4.69987 3.30055 5.39525 2.0442 6.64928 1.14525C7.74791 0.362066 8.99727 0.0334096 10.322 0.0046618C10.4572 0.00155393 10.5924 0 10.7276 0Z" />
              </svg>
            </a>

            <a href="https://twitter.com/sendasloth">
              <svg
                width={22}
                height={17}
                viewBox="0 0 27 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="cursor-pointer hover:fill-[#1BA2DD] fill-white"
              >
                <path d="M26.1061 3.16355C25.9204 3.44404 25.7347 3.7051 25.5202 3.95606C24.9733 4.6328 24.3408 5.23261 23.6307 5.74464L23.5429 5.90857C23.5662 6.67233 23.5312 7.43609 23.4286 8.19985C23.2228 9.80506 22.7566 11.3419 22.069 12.8018C21.3425 14.3099 20.4133 15.6727 19.2439 16.8716C17.2564 18.9111 14.8781 20.2257 12.109 20.8349C11.1596 21.0377 10.2024 21.1441 9.22571 21.1736C9.06954 21.1775 8.91415 21.1798 8.75953 21.1798C5.96013 21.1798 3.36583 20.434 0.976662 18.95L0.851562 18.8723C1.26103 18.9165 1.66583 18.9383 2.06597 18.9383C3.43187 18.9383 4.7465 18.6827 6.02927 18.1668C6.9119 17.7993 7.72694 17.3354 8.49536 16.7263C5.81871 16.6393 4.15134 14.7062 3.71857 13.1398C4.03946 13.2027 4.35957 13.233 4.67813 13.233C5.09691 13.233 5.51492 13.181 5.93292 13.0823L6.01062 13.0435C4.55303 12.7047 3.43731 11.9316 2.67355 10.6745C2.15842 9.84313 1.92378 8.92476 1.93077 7.92946C2.66267 8.31639 3.41556 8.52851 4.22748 8.54793C3.14051 7.76475 2.41405 6.73061 2.12424 5.41598C1.83443 4.10135 2.03722 2.85432 2.71318 1.67489C5.48228 4.97155 9.00661 6.77878 13.325 7.07869C13.2978 6.8658 13.2706 6.69176 13.2426 6.50839C13.1074 5.29011 13.3669 4.15885 14.032 3.1348C14.8634 1.83883 16.0397 1.037 17.5494 0.79536C17.8702 0.741749 18.1841 0.715332 18.4895 0.715332C19.8414 0.715332 21.0372 1.23435 22.0628 2.23508C22.1272 2.29335 22.1809 2.32365 22.2523 2.32365C22.2764 2.32365 22.3028 2.32054 22.3324 2.31277C23.3976 2.09056 24.4061 1.70363 25.3478 1.16209L25.4161 1.12402C25.0393 2.32288 24.288 3.25057 23.2197 3.93663C24.2258 3.83019 25.1753 3.55981 26.1061 3.16355Z" />
              </svg>
            </a>

            <a href="https://www.instagram.com/sendasloth/">
              <svg
                width={18}
                height={18}
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="cursor-pointer hover:fill-[#1BA2DD] fill-white"
              >
                <path d="M18.505 4.18652H18.5221C19.3146 4.18652 19.9571 4.83451 19.9517 5.63634C19.9455 6.42962 19.2936 7.07684 18.4902 7.07684C17.6876 7.06751 17.0552 6.41952 17.0606 5.62702C17.0668 4.83063 17.7101 4.18652 18.505 4.18652Z" />
                <path d="M23.9321 6.04247C24.1108 7.93749 24.2266 16.8889 23.6687 18.9867C22.9228 21.7318 21.1319 23.3564 18.3543 23.8008C17.3163 23.9679 14.513 24.0689 11.7128 24.0689C8.84732 24.0689 5.98497 23.9632 5.02387 23.7138C2.29361 23.0083 0.66353 21.2298 0.229982 18.4646C-0.0924665 16.4344 -0.126646 6.99969 0.459185 4.95005C1.22373 2.28195 3.00453 0.715586 5.70528 0.271161C6.92899 0.070704 9.32205 0 11.7376 0C13.9846 0 16.2518 0.0613804 17.6162 0.135969C19.2688 0.222989 20.7823 0.754434 22.0107 1.93387C23.1816 3.05503 23.7798 4.45746 23.9321 6.04247ZM21.6168 18.0684C21.9617 15.9511 21.9625 8.2273 21.7652 6.24526C21.5492 4.1288 20.2547 2.73647 18.1593 2.40782C17.1228 2.25009 14.6248 2.18017 12.1401 2.18017C9.74317 2.18017 7.35787 2.24543 6.3082 2.35965C4.20806 2.60128 2.79709 3.89649 2.46143 5.98419C2.11646 8.19855 2.12114 16.4732 2.37909 18.1359C2.64635 19.8663 3.62302 21.0558 5.34941 21.5391C6.28411 21.8001 9.08119 21.8941 11.8239 21.8941C14.3498 21.8941 16.8283 21.8149 17.7607 21.7131C19.8795 21.4808 21.2819 20.1755 21.6168 18.0684Z" />
                <path d="M12.0907 5.86868C15.493 5.86868 18.2373 8.6339 18.2108 12.0463C18.1852 15.4487 15.399 18.2139 11.9866 18.2139C8.57413 18.2139 5.83997 15.4487 5.8664 12.0362C5.89204 8.6238 8.67823 5.85935 12.0907 5.86868ZM12.0005 16.0291C14.1885 16.0291 15.9903 14.2669 16.026 12.0751C16.0625 9.86151 14.3066 8.06361 12.1031 8.04418C12.0907 8.0434 12.079 8.0434 12.0674 8.0434C9.87943 8.0434 8.07764 9.80556 8.04191 11.9974C8.00538 14.2016 9.76055 16.0096 11.9648 16.0291H12.0005Z" />
              </svg>
            </a>

            <a href="https://www.linkedin.com/company/sendasloth">
              <svg
                width={17}
                height={17}
                viewBox="0 0 23 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="cursor-pointer hover:fill-[#1BA2DD] fill-white"
              >
                <path d="M3.16899 0.420898C4.63821 0.420898 5.8091 1.60966 5.79744 3.06958C5.78656 4.5295 4.59781 5.71826 3.12858 5.71826C1.66865 5.71826 0.48921 4.5295 0.500074 3.06958C0.510969 1.60966 1.70904 0.420898 3.16899 0.420898Z" />
                <path d="M5.40504 7.71973L5.29315 22.423H0.720703L0.832581 7.71973H5.40504Z" />
                <path d="M17.0374 7.36182C21.6681 7.36182 22.4955 10.3974 22.4653 14.3607L22.4039 22.4233H17.8408L17.8951 15.2791C17.9084 13.5682 17.8866 11.3733 15.5471 11.3733C13.1689 11.3733 12.7975 13.2295 12.7827 15.1532L12.7268 22.4233H8.1543L8.26697 7.71922H12.646L12.6304 9.73001H12.698C13.3157 8.5801 14.8137 7.36182 17.0374 7.36182Z" />
              </svg>
            </a>
            <a href="https://www.tiktok.com/@sendasloth">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                viewBox="0 0 24 24"
                className="cursor-pointer hover:fill-[#1BA2DD] fill-white"
              >
                <path d="M19.59 6.69a4.83 4.83 0 0 1-3.77-4.25V2h-3.45v13.67a2.89 2.89 0 0 1-5.2 1.74 2.89 2.89 0 0 1 2.31-4.64 2.93 2.93 0 0 1 .88.13V9.4a6.84 6.84 0 0 0-1-.05A6.33 6.33 0 0 0 5 20.1a6.34 6.34 0 0 0 10.86-4.43v-7a8.16 8.16 0 0 0 4.77 1.52v-3.4a4.85 4.85 0 0 1-1-.1z" />
              </svg>
            </a>
          </div>
        </div>
      </div>
      <div className="border-t border-gray-500 py-4 lg:py-2 flex items-center justify-center text-sm text-white bg-[#2c436e] h-auto w-screen">
        <p className="text-center text-gray-200">
          Copyright © {new Date().getFullYear()} Send a Sloth All Rights
          Reserved
        </p>
      </div>
    </>
  );
}

export default Footer;
