import React from "react";

function Layout({ children }) {
  return (
    <div className="w-screen h-auto py-2 flex items-center justify-center">
      <div className="w-[95%] lg:w-[90%] h-auto py-2">{children}</div>
    </div>
  );
}

export default Layout;
